/* eslint-disable */
import React from 'react'
import Link from 'gatsby-link'
import Img from "gatsby-image";
import Layout from '../components/layout'
import { graphql } from 'gatsby'

import SEO from "../components/seo.js";
import Header from "../components/header.js";
import GeneralFooter from '../components/generalFooter';

class PrivacyPolicy extends React.Component {
  render() {
    const data = this.props.data.nodeLegal
    const retailers = this.props.data.allNodeStore.edges
    return (
      <Layout>
        <div className="page-wrapper">
          <SEO meta={data.field_meta_tags} />
          <Header retailers={retailers}></Header>
          <div style={{clear:'both'}} />

          <main className="privacy-policy-wrapper">
            <h1 className="sr-only">Privacy Policy</h1>
            <div className="intro-bold font-36 dark-grey-text blog-title">{data.title}</div>
            <div className="intro-bold grey-text privacy-subheader date">Last Updated on ({data.field_last_updated_date})</div>

            <div dangerouslySetInnerHTML={{__html: data.field_legal_body.value}}></div>

          </main>

        </div>
        <GeneralFooter />
      </Layout>
    )
  }
}

export default PrivacyPolicy

export const query = graphql`
query PrivacyPolicy {
  nodeLegal(uuid: {eq:"940bbf51-08a4-45c9-a0d0-5f85bf738b6a"}) {
    id
    title
    field_meta_tags
    field_last_updated_date
    field_legal_body {
      value
    }
  }
  allNodeStore {
    edges {
      node {
        id
        title
        relationships {
          field_image {
            localFile {
              childImageSharp {
                resolutions(width: 143) {
                  src
                }
              }
            }
          }
          field_state {
            name
          }
        }
      }
    }
  }
}
`
